import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login, verifyToken } from "../../services/account";
import { ThemeContext } from "../Wrapper";
import { v4 as uuidv4 } from "uuid";

const LoginForm = ({
  urlHotelName
}) => {
  const navigate = useNavigate();

  const context = useContext(ThemeContext);

  const [showPassword, setShowPassword] = useState(false);

  const [errors, setErrors] = useState({});

  const [customerInformation, setCustomerInformation] = useState({
    email: "",
    password: "",
  });

  const [loginError, setLoginError] = useState({
    showLoginError: false,
    message: "",
  });

  const handleMouseEnter = (isHovered, email) => {
    context.showLogin(isHovered, email);
  };

  const handleMouseLeave = () => {
    context.showLogin(false, context.isHovering.email);
  };

  const handleMouseEnterLogout = (isHovered) => {
    console.log("handleMouseEnterLogout: ", isHovered);
    context.showLogout(isHovered);
  };

  const handleMouseLeaveLogout = () => {
    console.log("handleMouseLeaveLogout");
    context.showLogout(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCustomerInformation((item) => ({
      ...item,
      [name]: value,
    }));

    validate({ [name]: value });
    formIsValid({
      ...customerInformation,
      [name]: value,
    });

    setLoginError({ ...loginError, showLoginError: false });
  };

  const signIn = () => {
    console.log("signIn");
    validate(customerInformation);
    if (formIsValid(customerInformation)) {
      context.showError(true);
      context.showLoader(true);
      if (customerInformation.email === "") {
        customerInformation.email = context.isHovering.email;
      }
      customerInformation.isPartner = false;
      customerInformation.browser = uuidv4();
      login(customerInformation)
        .then((results) => {
          if (results.data.status === 200) {
            localStorage.setItem(
              "user_session",
              JSON.stringify(results.data.user_session)
            );
            localStorage.setItem(
              "user_attribute",
              JSON.stringify(results.data.user_attr)
            );
            context.setIsHovering({ ...context.isHovering, isHovered: false });
            // navigate(-1); // TO_FIX: when I open a new tab and login directly, it redirects to new tab
          } else {
            setLoginError({
              showLoginError: true,
              message: results.data.message,
            });
          }
          context.showLoader(false);
        })
        .catch((error) => {
          setLoginError({
            showLoginError: true,
            message: error.message,
            // message: results.data.errors.email,
          });
          context.showLoader(false);
          context.showError(false);
        });
    }
  };

  const checkTokenValid = () => {
    if (JSON.parse(localStorage.getItem("user_session"))?.id_token) {
      verifyToken(JSON.parse(localStorage.getItem("user_session"))?.id_token)
        .then((result) => {
          context.setIsTokenValid(result.data.is_id_token_valid, "checkTokenValid");
        })
        .catch((error) => {
          throw error;
        });
    } else {
      context.setIsTokenValid(false, "checkTokenValid");
    }
  };

  const logout = () => {
    context.setIsTokenValid(false, "logout");
    context.showLogout(false);
    context.showLoader(true);
    // localStorage.clear();
    localStorage.removeItem("user_session");
    localStorage.removeItem("user_attribute");
    setTimeout(() => {
      context.showLoader(false);
    }, 500);
  };

  useEffect(() => {
    checkTokenValid();
  });

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email
        ? ""
        : context.translate("this_field_is_required");
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email invalide.";
    }

    if ("password" in fieldValues) {
      temp.password = fieldValues.password
        ? ""
        : context.translate("this_field_is_required");
    }

    setErrors({
      ...temp,
    });
  };

  const formIsValid = (customerInformation) => {
    const isValid =
      customerInformation.email &&
      customerInformation.password &&
      Object.values(errors).every((x) => x === "");
    return isValid;
  };

  const test = () => {
    console.log("test");
  }

  return (
    <>
      <div
        className="btn loginBox"
        onClick={
          !context.isTokenValid
            ? () => handleMouseEnter(true, context.isHovering.email)
            : !context.isHoveringLogout.isHovered ? () => handleMouseEnterLogout(true)
              : () => { }
        }
        onMouseLeave={
          !context.isTokenValid ? handleMouseLeave : handleMouseLeaveLogout
        }
      >
        <a style={{ cursor: 'pointer' }}>
          <span></span>
          <i>
            {context.isTokenValid &&
              JSON.parse(localStorage.getItem("user_attribute"))?.booker_first_name
              ? `${JSON.parse(localStorage.getItem("user_attribute"))
                ?.booker_first_name
              }`
              : context.translate("login")}
          </i>
        </a>
        <div
          style={{
            top: context.isHovering.isHovered ? "46px" : "auto",
            opacity: context.isHovering.isHovered ? 1 : 0,
            visibility: context.isHovering.isHovered ? "visible" : "hidden",
          }}
          className="boxLogin"
        >
          <div className="h3">
            {context.translate("login_with_your_account")}
          </div>
          <div className="contFormControl mail">
            <input
              className={
                errors.email ? "form-control errorForm" : "form-control"
              }
              type="email"
              placeholder={
                context.isHovering.email !== ""
                  ? context.isHovering.email
                  : context.translate('email')
              }
              name="email"
              onChange={handleChange}
              autoComplete="false"
            />
            {errors.email && <div className="textError">{errors.email}</div>}
          </div>
          <div className="contFormControl password">
            <input
              className={
                errors.password ? "form-control errorForm" : "form-control"
              }
              type={showPassword ? "text" : "password"}
              placeholder={context.translate("password")}
              name="password"
              onChange={handleChange}
              autoComplete="false"
            />

            {errors.password && (
              <div className="textError">{errors.password}</div>
            )}

            <span
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              className="material-symbols-rounded pointer"
            >
            </span>
          </div>
          <a //href={`/${urlHotelName}/password-reset`}
            className="forgotPsw"
            onClick={() => {
              navigate(`/${urlHotelName}/password-reset`);
            }}
          >
            {context.translate("forgotten_password")}
          </a>
          <a className="btn-primary" onClick={() => signIn()}>
            {context.translate("login")}
          </a>
          {loginError.showLoginError && (
            <p style={{ color: "red", textAlign: "center" }}>
              {loginError.message}
            </p>
          )}
          <a className="pas-de-compte">
            {context.translate("no_online_account")}
          </a>
          <div className="inscrit">
            <p>
              <a
                onClick={() => {
                  navigate(`/${urlHotelName}/register`);
                }}
                style={{
                  cursor: 'pointer'
                }}
              >
                {context.translate("register_today")}
              </a>{" "}
              {context.translate(
                "to_gain_more_time_during_your_next_reservation"
              )}
            </p>
          </div>
        </div>
        <div
          style={{
            top: context.isHoveringLogout.isHovered ? "46px" : "auto",
            opacity: context.isHoveringLogout.isHovered ? 1 : 0,
            visibility: context.isHoveringLogout.isHovered
              ? "visible"
              : "hidden",
          }}
          className="boxLogin"
        >
          {/* <a
            className="forgotPsw"
            onClick={() => {
              navigate(`/${urlHotelName}/password-reset`);
            }}
          >
            {context.translate("forgotten_password")}
          </a> */}
          {/* <button onClick={test}>test</button> */}
          <a className="btn-primary" onClick={logout}>
            {context.translate("disconnect")}
          </a>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
