import React, { useEffect, useState, useContext, useRef, } from "react";
import { useNavigate, useLocation, } from "react-router-dom";
import { withCookies } from "react-cookie";
import Promotion from "../../components/home/Promotion";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { getAllPromotions } from "../../services/promotion";
import { ThemeContext } from "../../components/Wrapper";
import { getAllFiltersForSearchRooms, revertInventory, searchRooms } from "../../services/room";
import { translate } from "../../services/translate";
import RoomSearchResult from "../../components/home/RoomSearchResult";
import RoomSearchForm from "../../components/home/RoomSearchForm";

import Cart from "../../components/home/Cart";
import CartMobile from "../../components/home/CartMobile";
import { buildDate, formatDate, getDiffDays, getFormatedDate } from "../../services/utils";
import LeaderBoard from "../../components/LeaderBoard";
import { getDaysBetweenToDate } from "../../services/date";
import CartTimeUp from "../../components/home/CartTimeUp";
import TimeUp from "../../components/TimeUp";
import { deleteImpendingReservation } from "../../services/booking";
import { getDateMadagascar } from "../../services/date";
import { getDataForCalendar } from "../../services/roomSearchForm";
import NavigationSteps from "../../components/NavigationSteps";

// VAOVAO
// import nosyTanyHely from "../styles/parc/images/banner.jpg";
import nosyTanyHely from "../../styles/parc/images/banner-2.jpeg";
import NavigationLocation from "../../components/NavigationLocation";
import { storageChange } from "../../services/dispatch";
import { checkDateDispo } from "../../services/calendar";
import ico1 from "../../styles/parc/images/ico1.png";
import ico3 from "../../styles/parc/images/ico3.png";
import ico4 from "../../styles/parc/images/ico4.png";

const getFirstDayOfMonths = () => {
  let firstDayOfFirstMonth = new Date();
  firstDayOfFirstMonth.setDate(1);
  let firstDayOfSecondMonth = new Date(firstDayOfFirstMonth);
  firstDayOfSecondMonth.setMonth(firstDayOfSecondMonth.getMonth() + 1);

  return {
    firstDayOfFirstMonth: buildDate(firstDayOfFirstMonth),
    firstDayOfSecondMonth: buildDate(firstDayOfSecondMonth),
  };
};

const getLastDayOfMonth = (date) => {
  let lastDay = new Date(date);
  lastDay.setDate(1);
  lastDay.setMonth(lastDay.getMonth() + 1);
  lastDay.setDate(0);
  lastDay = buildDate(lastDay);

  return lastDay;
};

// fill missing week days at month's start
const fillMissingWeekDaysAtMonthStart = (dates) => {
  const date = new Date(dates[0].date);
  let numDay = date.getDay();
  // if (numDay === 0) numDay = 7;
  for (let i = 0; i < numDay; i++) {
    const dateTemp = new Date(dates[0].date);
    dateTemp.setDate(dateTemp.getDate() - 1);
    dates.unshift({
      date: buildDate(dateTemp),
      num_day: null,
      to_pay: null,
      to_pay_without_promotion: null,
      promotion_name: null,
      promotion_value: null,
      is_promotion_percentage: null,
    });
  }
};

const initDataCalendar=(date)=>{
  const lastDay = new Date(date);
  // Passer au mois suivant
  lastDay.setMonth(lastDay.getMonth() + 1);
  // Définir le jour sur le dernier jour du mois précédent
  lastDay.setDate(0);

  const DayFirst = new Date(date).getDay();
  const d = new Date(date);
  // Décrémenter le mois
  //d.setMonth(d.getMonth());
  d.setDate(0);
  d.setDate(d.getDate()-(DayFirst-1));
  let prices = [];
  for (let i = 0; i < DayFirst-1; i++) {
    d.setDate(d.getDate()+1);
    prices.push({
        date:d.toISOString().split('T')[0],
        is_promotion_percentage:null,
        num_day:null,
        to_pay:null,
        to_pay_without_promotion:null,
      });
  }
  
  for (let i = 0; i < lastDay.getDate(); i++) {
    d.setDate(d.getDate()+1);
    prices.push({
      date:d.toISOString().split('T')[0],
      is_promotion_percentage:null,
      num_day:i+1,
      to_pay:null,
      to_pay_without_promotion:null,
    });
  }
  lastDay.setDate(1);
  const data = {
    month: lastDay.toISOString(),
    prices:prices
  }
  return data;
}

let timerDeleteCartId = null;
let isFirstRender = true;
let isResultAvailableRoomReceived = false;

const App = ({ urlHotelName }) => {

  // VAOVAO
  const [datePayload, setDatePayload] = useState(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart"))[0].datePayload : buildDate(new Date()));
  const initDatePayload = () => {
    if (localStorage.getItem('cart')) {
      let cartFromLocalStorage = JSON.parse(localStorage.getItem('cart'))[0];
      // console.log('cartFromLocalStorage initDatePayload : ', cartFromLocalStorage);
      if (
        cartFromLocalStorage.datePayload === "" ||
        getDiffDays(new Date(), new Date(cartFromLocalStorage.datePayload)) < 0
      ) {
        // console.log('buildDate(new Date()) : ', buildDate(new Date()));
        setDatePayload(buildDate(new Date()));
        setCheckIn(buildDate(new Date()));
        // console.log('initDatePayload & checkIn done');
      }
    }
    // else {
    //   // console.log('initDatePayload & no cart');
    //   const payload = {
    //     date: datePayload,
    //     hotel: JSON.parse(sessionStorage.getItem('hotel'))
    //   };
    //   checkDateDispo(payload).then((res) => {
    //     console.log('checkDatePayload response.data : ', res.data.date);
    //     setDatePayloadInit(res.data.date);
    //     setDatePayload(res.data.date);
    //     setCheckIn(res.data.date);
    //   }).catch((e) => {
    //     console.log('checkDatePayload error : ', e);
    //   })
    // }
  }

  useEffect(() => {
    initDatePayload();
  }, []);

  const context = useContext(ThemeContext);

  const [promotions, setPromotions] = useState(null);

  const [rooms, setRooms] = useState([]);

  const [filtersForSearchForRooms, setFiltersForSearchForRooms] = useState([]);

  const [isModalTimeUpOpened, setIsModalTimeUpOpened] = useState(false);

  const [isModalWarningTimeUpOpened, setIsModalWarningTimeUpOpened] = useState(
    false
  );
  const [checkIn, setCheckIn] = useState(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart"))[0].datePayload : buildDate(new Date()));
  // const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [dateMadagascar, setDateMadagascar] = useState(new Date());

  const location = useLocation();

  const navigate = useNavigate();

  const dateTomorrow = new Date();

  dateTomorrow.setDate(dateTomorrow.getDate() + 1);

  const [searchRoomPayload, setSearchRoomPayload] = useState({
    filtres: [
      {
        name: "affichage",
        label: "Affichage",
        multipleChoice: false,
        value: "Afficher par chambres",
        options: [
          {
            label: "Afficher par chambres",
            value: "Afficher par chambres",
            checked: false,
          },
          {
            label: "Afficher par tarifs",
            value: "Afficher par tarifs",
            checked: false,
          },
        ],
      },
      {
        name: "accessibilité",
        label: "Accessibilité",
        multipleChoice: true,
        options: [
          {
            label: "Chambres accessibles",
            value: "chambres accessibles",
            checked: false,
          },
        ],
      },
      {
        name: "trierpar",
        label: "Trier par",
        multipleChoice: false,
        value: "Prix le plus élevé",
        options: [
          // {
          //   label: "Recommandé",
          //   value: "Recommandé",
          //   checked: false,
          // },
          {
            label: "Prix le plus bas",
            value: "Prix le plus  bas",
            checked: false,
          },
          {
            label: "Prix le plus élevé",
            value: "Prix le plus élevé",
            checked: false,
          },
        ],
      },
      {
        name: "equipements",
        label: "Equipements",
        multipleChoice: true,
        options: [
          {
            label: "Cafetière",
            value: "61fc4b72706cc805d14aaac3",
            checked: false,
          },
          {
            label: "Télévision",
            value: "61fc3b90706cc805d14aaac1",
            checked: false,
          },
          {
            label: "Wifi",
            value: "6193e0e510749a3eb138c894",
            checked: false,
          },
          {
            label: "Air conditionné",
            value: "61fc49dd706cc805d14aaac2",
            checked: false,
          },
        ],
      },
      {
        name: "type de lit",
        label: "Type de lit",
        multipleChoice: true,
        options: [
          {
            label: "Double",
            value: "double",
            checked: false,
          },
          {
            label: "King",
            value: "king",
            checked: false,
          },
        ],
      },
      {
        name: "type de chambre",
        label: "Type de chambre",
        multipleChoice: true,
        options: [
          {
            label: "Chambre standard",
            value: "620f4277d5360b160f48908e",
            checked: false,
          },
          {
            label: "Double Deluxe",
            value: "620f4e75d5360b160f4890a7",
            checked: false,
          },
          {
            label: "FRtypeChambreTest",
            value: "624d4d1c1129ec1768c75373",
            checked: false,
          },
        ],
      },
      {
        name: "plan tarifaire",
        label: "Plan tarifaire",
        multipleChoice: true,
        options: []
      }
    ],
    nbEnfant: 0,
    nbAdulte: 1,
    dateDebut:
      JSON.parse(localStorage.getItem("cart"))?.length > 0
        ? JSON.parse(localStorage.getItem("cart"))[0].dateSejour.debut
        : "",
    dateFin:
      JSON.parse(localStorage.getItem("cart"))?.length > 0
        ? JSON.parse(localStorage.getItem("cart"))[0].dateSejour.fin
        : "",
    numPage: 1,
    regrouperParTypeChambre: "true",
    // VAOVAO
    datePayload: datePayload
  });

  const [daysFirstMonth, setDaysFirstMonth] = useState([]);
  const [daysSecondMonth, setDaysSecondMonth] = useState([]);
  const [areDaysToBookReloading, setAreDaysToBookReloading] = useState(false);
  const [infoCalendar, setInfoCalendar] = useState({
    ...getFirstDayOfMonths(),
    // NIALA
    // show: JSON.parse(localStorage.getItem("displayCalendar")) ? true : false,
    show: true,
  });

  const [isPromotionResultReceived, setIsPromotionResultReceived] = useState(false);
  const [isFilterForSearchRoomsResultReceived, setIsFilterForSearchRoomsResponseReceived] = useState(false);
  const [isCalendarContentReceived, setIsCalendarContentReceived] = useState(false);
  const [isSearchRoomResultReceived, setIsSearchRoomResultReceived] = useState(false);
  const [nightsNum, setNightsNum] = useState(0);
  const [cartExpirationDate, setCartExpirationDate] = useState(null);
  const [totalAllItinerary, setTotalAllItinerary] = useState(0);
  const [newRoomAdded, setNewRoomAdded] = useState(null);
  const [isCalendarContentLoading, setIsCalendarContentLoading] = useState(false);
  const [showNavigationSteps, setShowNavigationSteps] = useState(false);
  const [printNoResult, setPrintNoResult] = useState(false);
  const [cart, setCart] = useState(null);
  const [totalPriceMontant, setTotalPriceMontant] = useState(0);

  // VAOVAO

  const [nbPersonneActivite, setNbPersonneActivite] = useState([]);

  const [offresDispo, setOffresDispo] = useState({
    date: '15/01/2024',
    activites: [
      {
        _id: 1,
        nom: "PLONGEE",
        offres: [
          {
            _id: 1,
            nom: "NATIONAUX",
            prixAdulte: 15000,
            prixEnfant: 10000
          },
          {
            _id: 2,
            nom: "ETRANGERS",
            prixAdulte: 20000,
            prixEnfant: 20000
          }
        ]
      },
      {
        _id: 2,
        nom: "ENTREE PARC",
        offres: [
          {
            _id: 1,
            nom: "NATIONAUX",
            prixAdulte: 30000,
            prixEnfant: 10000
          }
        ]
      }
    ]
  });

  const [offres, setOffre] = useState([
    {
      id: 1,
      nom: "NATIONAUX",
      tarifs: [
        {
          id: 1,
          nom: "adults",
          info: "+12 ans",
          prix: 400000
        },
        {
          id: 2,
          nom: "children",
          info: "de 4 jusqu'à 11 ans",
          prix: 400000
        }
      ]
    },
    {
      id: 2,
      nom: "ETRANGER",
      tarifs: [
        {
          id: 1,
          nom: "adults",
          info: "+12 ans",
          prix: 500000
        },
        {
          id: 2,
          nom: "children",
          info: "de 4 jusqu'à 11 ans",
          prix: 500000
        }
      ]
    }
  ]);

  const [nbPersonneTarif, setNbPersonneTarif] = useState([]);
  useEffect(() => {
    let nbPersonneTarifTmp = nbPersonneTarif;
    offres.forEach(offre => {
      let tabTmp = [];
      for (let j = 0; j < offre.tarifs.length; j++) {
        tabTmp.push(1);
      }
      nbPersonneTarifTmp.push(tabTmp);
    });
    setNbPersonneTarif(nbPersonneTarifTmp);
  }, [offres]);

  const [sousTotalPrix, setSousTotalPrix] = useState([]);
  useEffect(() => {
    let sousTotalPrixTmp = sousTotalPrix;
    for (let index = 0; index < offres.length; index++) {
      sousTotalPrix.push(0);
    }
    setSousTotalPrix(sousTotalPrixTmp);
  }, [offres]);
  useEffect(() => {
    let sousTotalPrixTmp = [...sousTotalPrix];
    let nbPersonneTarifTmp = [...nbPersonneTarif];
    let offresTmp = [...offres];
    for (let index = 0; index < sousTotalPrix.length; index++) {
      let prix = 0;

      for (let j = 0; j < nbPersonneTarifTmp[index].length; j++) {
        let nb = nbPersonneTarifTmp[index][j];
        prix += (nb * offresTmp[index].tarifs[j].prix);
      }

      sousTotalPrixTmp[index] = prix;
    }
    setSousTotalPrix(sousTotalPrixTmp);
  }, [nbPersonneTarif]);


  const [prixTotalReservation, setPrixTotalReservation] = useState(0);
  useEffect(() => {
    let sousTotalPrixTmp = [...sousTotalPrix];
    let prixTotalTmp = 0;
    sousTotalPrixTmp.forEach(sousTotalPrix => {
      prixTotalTmp += sousTotalPrix;
    });
    setPrixTotalReservation(prixTotalTmp);
  }, [sousTotalPrix]);

  const handleChangeNbPersonneTarif = (index, j, valueToAdd) => {
    let nbPersonneTarifTmp = JSON.parse(JSON.stringify(nbPersonneTarif));
    let value = nbPersonneTarifTmp[index][j] + valueToAdd;
    if (value <= 0) {
      value = 0;
    }
    nbPersonneTarifTmp[index][j] = value;
    setNbPersonneTarif(nbPersonneTarifTmp);
  }
  // 

  const isDateOfStayEqual = (object1, object2) => {
    return (
      object1.dateSejour.debut === object2.dateDebut &&
      object1.dateSejour.fin === object2.dateFin
    );
  };

  const handleChangeLocalStorage = async (room, tarif, price, categorieAge, valueToAdd) => {
    // console.log('room : ', room);
    // console.log('tarif : ', tarif);
    // console.log('price : ', price);
    // console.log('categorieAge : ', categorieAge);
    // console.log('valueToAdd before: ', valueToAdd);

    if (
      (!localStorage.getItem("cart") && valueToAdd < 0)
      || (localStorage.getItem("cart") && JSON.parse(localStorage.getItem("cart"))[0].tarifReserves.length == 0 && valueToAdd < 0)
    ) {
      return;
    }

    createTimerDeleteCart();
    const lang = localStorage.getItem('lang') || 'fr';
    const rateTemp = {
      _id: tarif._id,
      nomTarif: tarif.names[lang],
      description: tarif.descriptions[lang],
      adultsNum: price.adultsNum,
      childrenNum: price.childrenNum,
      hotelName: room.hotelName,
      nomTypeChambre: room.names[lang],
      superficie: room.superficie,
      chambreTotal: room.chambreTotal,
      photoCrop: room.photoCrop,
      nbNuit: getDaysBetweenToDate(new Date(checkIn), new Date(checkOut)),
      politiqueAnnulAtrb: tarif.politiqueAnnulAtrb,
      prix: price.prix,
      // VAOVAO
      categorieAge: categorieAge,
      nb: valueToAdd,
      isNationaux: tarif.isNationaux,
      idTypeChambre: room._id,
    };

    const prixOffre = 0;

    const newRoomAdded = {
      ...rateTemp,
      checkIn,
      checkOut,
    };
    localStorage.setItem('newRoomAdded', JSON.stringify(newRoomAdded));
    setNewRoomAdded(newRoomAdded);
    let cartFromLocalStorage = localStorage.getItem("cart");
    if (cartFromLocalStorage) {
      // console.log('nisy cart');
      cartFromLocalStorage = JSON.parse(cartFromLocalStorage);
      cartFromLocalStorage.some((cartTemp) => {
        if (true) {
          // if (
          //   isDateOfStayEqual(cartTemp, {
          //     ...searchRoomPayload,
          //     dateDebut: checkIn,
          //     dateFin: checkOut,
          //   })
          // ) {
          // cartTemp.tarifReserves.push(rateTemp);
          // VAOVAO
          // console.log('fdkfjkdjfkjdfkjdkj');
          let tarifReserve = cartTemp.tarifReserves.find((tarifReserve) =>
            tarifReserve.categorieAge === categorieAge &&
            tarifReserve.nomTarif === tarif.names[lang] &&
            tarifReserve.nomTypeChambre === room.names[lang]
          );
          if (tarifReserve) {
            // console.log('efa misy');
            // console.log('tarifReserve : ', tarifReserve);
            tarifReserve.nb += valueToAdd;
            if (tarifReserve.nb <= 0) {
              const index = cartTemp.tarifReserves.indexOf(tarifReserve);
              if (index !== -1) {
                cartTemp.tarifReserves.splice(index, 1);
              }
            }
          } else {
            // console.log('nakato tampoka !!');
            if (valueToAdd >= 0) {
              // console.log("valueToAdd after: ", valueToAdd);
              cartTemp.tarifReserves.push(rateTemp);
            }
          }

          cartTemp.totalByItinerary = 0;
          cartTemp.totalPrice = totalPriceMontant;
          for (let i = 0; i < cartTemp.tarifReserves.length; i++) {
            // console.log("handleChangeLocalStorage totalByItinerary: ", cartTemp.tarifReserves[i].prix, cartTemp.tarifReserves[i].nb);
            cartTemp.totalByItinerary += cartTemp.tarifReserves[i].prix * cartTemp.tarifReserves[i].nb;
            cartTemp.totalPrice += cartTemp.tarifReserves[i].prix * cartTemp.tarifReserves[i].nb;
          }

          // console.log("handleChangeLocalStorage cart: ", cartFromLocalStorage);
          setCart([...cartFromLocalStorage]);
          localStorage.setItem("cart", JSON.stringify(cartFromLocalStorage));

          context.refresh();
          return true;
        } else {
          // console.log('qwerty nakato');
          addAnotherItineraryToCart(rateTemp, room);
          return false;
        }
      });
    } else {
      // console.log('Tsy nisy cart');
      addAnotherItineraryToCart(rateTemp, room);
    }
    setShowNavigationSteps(true);

    // console.log('cart : ', JSON.parse(localStorage.getItem("cart")));

  };

  const removeItinerariesDuplicateInCart = (cart) => {
    for (let i = 0; i < cart.length; i++) {
      for (let u = i + 1; u < cart.length; u++) {
        if (
          cart[i].dateSejour.debut === cart[u].dateSejour.debut &&
          cart[i].dateSejour.fin === cart[u].dateSejour.fin
        ) {
          cart.splice(u);
          u = u - 1;
        }
      }
    }
  };

  const setTimerDeleteCart = () => {
    clearInterval(timerDeleteCartId);
    timerDeleteCartId = setInterval(() => {
      if (checkCartExpirationDate()) {
        clearInterval(timerDeleteCartId);
      }
    }, 1000);
  };

  const addAnotherItineraryToCart = (rateTemp, room) => {
    // console.log('addAnotherItineraryToCart');
    if (rateTemp.nb < 0) return;
    const cartToSendToCookieTemp = {
      nbAdulte: room.nbAdulte,
      nbEnfant: room.nbEnfant,
      _id: room._id,
      dateSejour: {
        debut: checkIn,
        fin: checkOut,
      },
      tarifReserves: [rateTemp],
      // VAOVAO
      datePayload: datePayload,
    };
    let cartFromLocalStorage = localStorage.getItem("cart");
    if (cartFromLocalStorage) {
      cartFromLocalStorage = JSON.parse(cartFromLocalStorage);
    }

    let cartTemp =
      cartFromLocalStorage && cartFromLocalStorage.length > 0
        ? [...cartFromLocalStorage]
        : [];
    // console.log('cartToSendToCookieTemp : ', cartToSendToCookieTemp);
    if (cartTemp.length > 0) {
      let tarifReserveTemp = cartToSendToCookieTemp.tarifReserves[0];

      let existTarifReserve = cartTemp[0].tarifReserves.find((tarifReserve) =>
        tarifReserve.nomTarif === tarifReserveTemp.nomTarif &&
        tarifReserve.nomTypeChambre === tarifReserveTemp.nomTypeChambre &&
        tarifReserve.categorieAge === tarifReserveTemp.categorieAge
      );

      if (existTarifReserve) {
        // console.log('efa misy eeeeee');
        // console.log('existTarif : ', existTarifReserve);
        existTarifReserve.nb += tarifReserveTemp.nb;
        if (existTarifReserve.nb === 0) {
          const index = cartTemp[0].tarifReserves.indexOf(existTarifReserve);
          if (index !== -1) {
            cartTemp[0].tarifReserves.splice(index, 1);
          }
        }
      } else {
        // console.log('mbola tsisy eeee');
        cartTemp[0].tarifReserves.push(tarifReserveTemp);
      }

    } else {
      cartTemp.push(cartToSendToCookieTemp);
    }
    removeItinerariesDuplicateInCart(cartTemp);
    if (cartTemp[cartTemp.length - 1].tarifReserves[0]) {
      cartTemp[cartTemp.length - 1].totalByItinerary = cartTemp[cartTemp.length - 1].tarifReserves[0].prix;
      cartTemp[cartTemp.length - 1].totalPrice = cartTemp[cartTemp.length - 1].tarifReserves[0].prix;
    }
    setCart(cartTemp);
    localStorage.setItem("cart", JSON.stringify(cartTemp));
    localStorage.setItem("cartOwner", urlHotelName);

    context.refresh();
  };

  const createTimerDeleteCart = () => {
    let cartFromLocalStorage = localStorage.getItem("cart");
    let roomAlreadyExists = false;
    if (cartFromLocalStorage) {
      cartFromLocalStorage = JSON.parse(cartFromLocalStorage);
    } else {
      cartFromLocalStorage = [];
    }

    for (let i = 0; i < cartFromLocalStorage.length; i++) {
      if (cartFromLocalStorage[i].tarifReserves.length > 0) {
        roomAlreadyExists = true;
        break;
      }
    }
    if (roomAlreadyExists) return;

    let cartExpirationDateTmp = new Date();
    // console.log("createTimerDeleteCart cartExpirationDateTmp now: ", cartExpirationDateTmp);
    cartExpirationDateTmp.setMinutes(cartExpirationDateTmp.getMinutes() + 15); //ExprVal
    // @ts-ignore
    cartExpirationDateTmp = cartExpirationDateTmp.toISOString();
    // console.log("createTimerDeleteCart cartExpirationDateTmp end: ", cartExpirationDateTmp);
    localStorage.setItem("cartExpirationDate", cartExpirationDateTmp.toString());
    // @ts-ignore
    setCartExpirationDate(cartExpirationDateTmp);
    setIsModalWarningTimeUpOpened(true);
    setTimerDeleteCart();
  };

  const handleChangeRoomSearchForm = (
    e,
    nameCheck,
    valueCheck,
    checked,
    multipleChoice
  ) => {
    // console.log("rerererererererererererererererererer")
    buildFilterForSearchRoomsPayload(
      nameCheck,
      valueCheck,
      checked,
      multipleChoice
    );
    searchRoom({
      searchRoomPayloadArg: {
        ...searchRoomPayload,
        ...filtersForSearchForRooms,
        dateDebut: checkIn,
        dateFin: checkOut,
      },
    });
  };

  const buildFilterForSearchRoomsPayload = (
    name,
    value,
    checked,
    multipleChoice
  ) => {
    const filtersForSearchRoomsTemp = [...filtersForSearchForRooms];
    filtersForSearchRoomsTemp.forEach((filter) => {
      if (filter.name === name) {
        filter.options.forEach((option) => {
          if (!multipleChoice) {
            option.checked = false;
          }
          if (option.value === value) {
            option.checked = checked;
          }
        });
      }
    });
    setFiltersForSearchForRooms(filtersForSearchRoomsTemp);
  };

  const getFilterForSearchRooms = () => {
    context.showError(true);
    setFiltersForSearchForRooms([]);

    getAllFiltersForSearchRooms(context.getHotelName())
      .then((results) => {
        setIsFilterForSearchRoomsResponseReceived(true);
        if (results.data.filters.length > 0) {
          setFiltersForSearchForRooms(results.data.filters);
        } else {
          setFiltersForSearchForRooms([]);
        }
        // context.showLoader(false);
      })
      .catch((error) => {
        // context.showLoader(false);
        context.showError(false);
      });
  };
  const formatPromotionsToTranslate = (payload) => {
    return {
      planTarifaire: payload?.tarifPlan,
      typeChambre: payload?.roomType,
    };
  };
  const formatTranslateV1Payload = (payload) => {
    return {
      text: payload?.text,
      to_language: payload?.to_language,
    };
  };
  const formatTranslatedPromotions = (payload) => {
    return {
      ...payload,
      planTarifaire: payload?.tarifPlan,
      typeChambre: payload?.roomType,
    };
  };

  const getPromotions = () => {
    // context.showError(true);
    getAllPromotions(urlHotelName)
      .then((results) => {
        setIsPromotionResultReceived(true);
        if (results.data.promotions.length > 0) {
          const promotions_list = results.data.promotions;
          setPromotions(promotions_list);
        } else {
          setPromotions([]);
        }
      })
      .catch((error) => {
        context.showError(false);
      });
  };

  const handleChangeOccupation = (occupation, valueToAdd) => {
    let value = searchRoomPayload[occupation] + valueToAdd;
    if (value <= 0) {
      value = 0;
    }

    setSearchRoomPayload({
      ...searchRoomPayload,
      [occupation]: value,
    });

    searchRoom({
      searchRoomPayloadArg: {
        ...searchRoomPayload,
        [occupation]: value,
        dateDebut: checkIn,
        dateFin: checkOut,
      },
    });
  };

  const giveNewValueToDaysBetweenCheckInAndCheckout = (
    prixNuiteeCalendrier
  ) => {
    const daysFirstMonthTemp = [...daysFirstMonth];
    const daysSecondMonthTemp = [...daysSecondMonth];
    const daysOfMonths = [daysFirstMonthTemp, daysSecondMonthTemp];

    daysOfMonths.forEach((daysOfMonth) => {
      for (let i = 0; i < daysOfMonth.length; i++) {
        for (let u = 0; u < prixNuiteeCalendrier.length; u++) {
          if (daysOfMonth[i].date === prixNuiteeCalendrier[u].date) {
            const { aPayer, prixOriginal, promotions } = prixNuiteeCalendrier[
              u
            ];
            daysOfMonth[i] = {
              ...daysOfMonth[i],
              to_pay: aPayer,
              to_pay_without_promotion: prixOriginal,
              promotion_name: promotions[0] ? promotions[0].nom : null,
              promotion_value: promotions[0] ? promotions[0].remise : null,
              is_promotion_in_percentage: promotions[0]
                ? promotions[0].isPourcentage
                : null,
            };
          }
        }
      }
    });

    setDaysFirstMonth(daysFirstMonthTemp);
    setDaysSecondMonth(daysSecondMonthTemp);
  };

  const searchRoom = ({
    searchRoomPayloadArg = {
      ...searchRoomPayload,
      dateDebut: checkIn,
      dateFin: checkOut,
      // VAOVAO
      datePayload: datePayload
    },
    doNotSetCalendarData = false,
  }) => {
    // VAOVAO
    let dateToSetPayload = new Date(searchRoomPayloadArg.datePayload);
    if (getDiffDays(new Date(), dateToSetPayload) < 0) {
      dateToSetPayload = buildDate(new Date());
    } else {
      dateToSetPayload = buildDate(dateToSetPayload);
    }
    setDatePayload(dateToSetPayload);

    let cartFromLocalStorage = localStorage.getItem("cart");
    let datePayloadTmp = "";
    if (cartFromLocalStorage) {
      cartFromLocalStorage = JSON.parse(cartFromLocalStorage)[0];
      datePayloadTmp = cartFromLocalStorage.datePayload;
    }

    if (datePayloadTmp === "") {
      // console.log('cartFromLocalStorage nakato 1');
      setCheckIn(buildDate(new Date()));
      setCheckOut(buildDate(new Date()));
    } else {
      // console.log('cartFromLocalStorage nakato 2');
      let dateToSet = new Date(datePayloadTmp);
      if (getDiffDays(new Date(), dateToSet) < 0) {
        dateToSet = buildDate(new Date());
      } else {
        dateToSet = buildDate(dateToSet);
      }
      // console.log('dateToSet next : ', dateToSet);
      setCheckIn(dateToSet);
      setCheckOut(dateToSet);
    }

    // setCheckIn(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart"))[0].datePayload : searchRoomPayloadArg.dateDebut);
    // setCheckOut(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart"))[0].datePayload : searchRoomPayloadArg.dateFin);

    // VAOVAO
    // setOffresDispo({ ...offresDispo, date: searchRoomPayloadArg.dateDebut });
    setOffresDispo({ ...offresDispo, date: dateToSetPayload });
    // setOffresDispo({ ...offresDispo, date: datePayload });
    context.showError(true);
    context.showLoader(true);
    setIsSearchRoomResultReceived(false);
    setRooms([]);
    !doNotSetCalendarData && setAreDaysToBookReloading(true);
    isResultAvailableRoomReceived = false;

    if (filtersForSearchForRooms.length > 0) {
      searchRoomPayloadArg.filtres = filtersForSearchForRooms;
    }
    const payload = {
      // datePayload: searchRoomPayloadArg.datePayload,
      datePayload: dateToSetPayload,
      filtres: searchRoomPayloadArg.filtres,
      guests: {
        nbEnfant: parseInt(searchRoomPayloadArg.nbEnfant),
        nbAdulte: parseInt(searchRoomPayloadArg.nbAdulte),
      },
      dateDebut: searchRoomPayloadArg.dateDebut,
      dateFin: searchRoomPayloadArg.dateFin,
      numPage: 1,
      regrouperParTypeChambre:
        searchRoomPayloadArg.regrouperParTypeChambre === "true" ? true : false,
      urlHotelName: urlHotelName,
    };
    // console.log("searchRooms payload : ", payload);
    // console.log("searchRoom: ", JSON.stringify(payload));
    searchRooms(payload)
      .then((results) => {
        // console.log("searchRoom result: ", results.data)
        // console.log("searchRoom result: ", JSON.stringify(results.data))
        isResultAvailableRoomReceived = true;
        setInfoCalendar({
          ...infoCalendar,
          // VAOVAO
          // show: false,
        });
        // 'results' contains the list of rooms
        if (results.data.list.length > 0) {
          setPrintNoResult(false);
          setNightsNum(results.data.nombreNuitees);
          // console.log("searchRoom doNotSetCalendarData: ", doNotSetCalendarData);
          if (!doNotSetCalendarData) {
            // giveNewValueToDaysBetweenCheckInAndCheckout(
            //   results.data.prixNuiteeCalendrier
            // );
            setAreDaysToBookReloading(false);
          }
          const list = results.data.list;
          // Map the room list to translate each object
          const listTranslated = list.map(async (room) => {
            // Payload to send to the translation api
            const language = localStorage.getItem("lang") || "fr";
            room.tarifs.forEach((tarif, index) => {
              tarif.nom = tarif.names[language];
              tarif.description = tarif.descriptions[language];
            });
            const newRoom = {
              ...room,
              nom: room.names[language],
              description: room.descriptions[language],
              tarifs: room?.tarifs,
            };
            return newRoom;
          });
          Promise.all(listTranslated).then((values) => {
            // console.log(values);
            setRooms(values);
          });
        } else {
          setPrintNoResult(true);
          setIsSearchRoomResultReceived(true);
          setAreDaysToBookReloading(false);
          setRooms([]);
          // setCheckIn(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart"))[0].datePayload : new Date());
          // setCheckOut(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart"))[0].datePayload : new Date());
          setCheckIn("");
          setCheckOut("");
        }
        // setTimeout(() => {context.showLoader(false)}, 5000);
      })
      .catch((error) => {
        context.showLoader(false);
        context.showError(false);
      });
  };

  const checkCartExpirationDate = () => {
    const cartExpirationDateExist = !!localStorage.getItem("cartExpirationDate");
    if (!cartExpirationDateExist && !!localStorage.getItem('cart')) {
      return false; // the cart still doesn't expire
    }

    if (
      // localStorage.getItem("isCartSubmitted") === null &&
      (
        localStorage.getItem("cartExpirationDate") !== null
        && new Date() >= new Date(localStorage.getItem("cartExpirationDate"))
        && localStorage.getItem("cart")
      ) || (
        localStorage.getItem("cartExpirationDate") === null
        && localStorage.getItem("cart")
      )
    ) {
      localStorage.removeItem("cartExpirationDate");
      localStorage.removeItem("isCartSubmitted");
      if (cartExpirationDateExist) {
        setIsModalTimeUpOpened(true);
      }

      return true; // the cart expired
    }

    return false; // the cart still doesn't expire
  };

  const checkEverythingLoaded = () => {
    if (
      // isPromotionResultReceived &&
      isCalendarContentReceived &&
      isSearchRoomResultReceived &&
      isFilterForSearchRoomsResultReceived &&
      timerDeleteCartId
    ) {
      return true;
    }
  };

  const getLastItineraryFromCart = () => {
    const cart = JSON.parse(localStorage.getItem("cart"));
    const lastItinerary = cart.slice(-1);
    if (lastItinerary.length > 0) {
      lastItinerary[0].tarifReserves = [];
      lastItinerary[0].totalPrice = 0;
    }
    return lastItinerary;
  }

  const getTotal = () => {
    // const cookiesTemp = context.cookies.get("cart");
    let cookiesTemp = localStorage.getItem("cart");
    if (cookiesTemp) {
      cookiesTemp = JSON.parse(cookiesTemp);
    }

    if (cookiesTemp) {
      let total = 0;
      let totalAllItineraryTemp = 0;

      cookiesTemp.forEach((itinerary) => {
        total = itinerary.tarifReserves
          .map((rate) => rate.prix)
          .reduce((prev, next) => prev + next, 0);
        itinerary.totalByItinerary = total;
        totalAllItineraryTemp += total;
      });

      cookiesTemp.totalAllItinerary = totalAllItineraryTemp;

      localStorage.setItem("cart", JSON.stringify(cookiesTemp));

      setTotalAllItinerary(totalAllItineraryTemp);
    }
  };

  const removeItemFromCart = (itineraryIndex, ratePlanIndex) => {
    context.showLoader(true);
    let cookiesTemp = localStorage.getItem("cart");
    if (cookiesTemp) {
      cookiesTemp = JSON.parse(cookiesTemp);
      cookiesTemp[itineraryIndex].tarifReserves.splice(ratePlanIndex, 1);

      const { debut, fin } = cookiesTemp[itineraryIndex].dateSejour;
      deleteImpendingReservation({
        cart_id: localStorage.getItem('cartId'),
        check_in: debut,
        check_out: fin,
      })
        .then(result => console.log(result))

      if (cookiesTemp[itineraryIndex].tarifReserves.length === 0) {
        cookiesTemp.splice(itineraryIndex, 1);
        if (cookiesTemp.length === 0) {
          setCart(null);
          localStorage.removeItem("isCartSubmitted");
          localStorage.removeItem("cart");
          setShowNavigationSteps(false);
        } else {
          setCart(cookiesTemp);
          localStorage.setItem("cart", JSON.stringify(cookiesTemp));
        }
      } else {
        setCart(cookiesTemp);
        localStorage.setItem("cart", JSON.stringify(cookiesTemp));
      }
      setTimeout(() => {
        context.showLoader(false);
      }, 500);
    }
  };

  const populateCalendar = ({
    infoCalendarArg,
    resetCheckInCheckOut = true,
  }) => {
    // console.log("MIDITRA ATO ")
    setIsCalendarContentLoading(true);

    getDataForCalendar({
      dateDebut: infoCalendarArg.firstDayOfFirstMonth,
      dateFin: getLastDayOfMonth(infoCalendar.firstDayOfSecondMonth),
      hotelName: context.getHotelName(),
    }).then((result) => {
      console.log("getDataForCalendar result: ", result);
      setIsCalendarContentReceived(true);
      setIsCalendarContentLoading(false);
      setIsSearchRoomResultReceived(true);
      if (result.data.status === 200) {
        const months = ["daysOfFirstMonth", "daysOfSecondMonth"];
        const daysOfMonth = {};
        result.data.result.forEach((monthDetails, i) => {
          daysOfMonth[months[i]] = monthDetails.prices;
          fillMissingWeekDaysAtMonthStart(daysOfMonth[months[i]]);
        });
        if (isFirstRender) {
          setIsSearchRoomResultReceived(false);
          if (!context.isRedirectFromBookingForm) {
            searchRoom({
              searchRoomPayloadArg: {
                ...searchRoomPayload,
                dateDebut: result.data.checkIn,
                dateFin: result.data.checkOut,
              },
              doNotSetCalendarData: true,
            });
          }
          isFirstRender = false;
        }
        const infoCalendarTemp = { ...infoCalendarArg };
        if (resetCheckInCheckOut && !context.isRedirectFromBookingForm) {
          // console.log('ary ato?');
          // console.log('result.data.checkIn : ', result.data.checkIn);
          // console.log('datePayload : ', datePayload);
          // setCheckIn(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart"))[0].datePayload : result.data.checkIn);
          setCheckIn(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart"))[0].datePayload : datePayload);
          setCheckOut(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart"))[0].datePayload : result.data.checkOut);
        }
        setInfoCalendar(infoCalendarTemp);

        setDaysFirstMonth(daysOfMonth.daysOfFirstMonth);
        setDaysSecondMonth(daysOfMonth.daysOfSecondMonth);
      }
      else if(result.data.status === 400){
        const d = new Date();
        const d2 = new Date();
        d2.setDate(d2.getDate()+1);
        const data = {
          checkIn: d.toISOString().split('T')[0],
          checkOut:d2.toISOString().split('T')[0],
          result:[initDataCalendar(infoCalendarArg.firstDayOfFirstMonth),initDataCalendar(infoCalendarArg.firstDayOfSecondMonth)]
        };
      // console.log("data",data);

        const months = ["daysOfFirstMonth", "daysOfSecondMonth"];
        const daysOfMonth = {};
        data.result.forEach((monthDetails, i) => {
          daysOfMonth[months[i]] = monthDetails.prices;
          fillMissingWeekDaysAtMonthStart(daysOfMonth[months[i]]);
        });
        if (isFirstRender) {
          setIsSearchRoomResultReceived(false);
          if (!context.isRedirectFromBookingForm) {
            searchRoom({
              searchRoomPayloadArg: {
                ...searchRoomPayload,
                dateDebut: data.checkIn,
                dateFin: data.checkOut,
              },
              doNotSetCalendarData: true,
            });
          }
          isFirstRender = false;
        }
        const infoCalendarTemp = { ...infoCalendarArg };
        if (resetCheckInCheckOut && !context.isRedirectFromBookingForm) {
          // console.log('ary ato?');
          // console.log('result.data.checkIn : ', result.data.checkIn);
          // console.log('datePayload : ', datePayload);
          // setCheckIn(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart"))[0].datePayload : result.data.checkIn);
          setCheckIn(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart"))[0].datePayload : datePayload);
          setCheckOut(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart"))[0].datePayload : data.checkOut);
        }
        setInfoCalendar(infoCalendarTemp);

        setDaysFirstMonth(daysOfMonth.daysOfFirstMonth);
        setDaysSecondMonth(daysOfMonth.daysOfSecondMonth);
      }
    });
  };

  const checkLastItinerary = () => {
    //TODO: to review
    // console.log("MIDITRA")
    const cart = JSON.parse(localStorage.getItem("cart"));
    const { debut, fin } = cart[cart.length - 1].dateSejour;
    searchRoom({
      searchRoomPayloadArg: {
        ...searchRoomPayload,
        dateDebut: debut,
        dateFin: fin,
      },
      doNotSetCalendarData: false,
    });
    context.setIsRedirectFromBookingForm(false);
  };

  useEffect(() => {
    // console.log('checkIn useEffect : ===>> : ', checkIn);
    if (localStorage.getItem("cart")) {
      let cartFromLocalStorage = JSON.parse(localStorage.getItem("cart"))[0];
      // console.log('cartFromStorage : ', cartFromLocalStorage);
      cartFromLocalStorage.datePayload = checkIn;
      localStorage.setItem("cart", JSON.stringify([cartFromLocalStorage]))
    }
  }, [checkIn]);

  useEffect(() => {
    checkCartExpirationDate();
    context.showLoader(true);
    // getPromotions();
    getFilterForSearchRooms();
    setTimerDeleteCart();
    getDateMadagascar()
      .then(date => {
        setDateMadagascar(date)
        context.showLoader(false);
      });

    let cartTemp = localStorage.getItem("cart");
    if (cartTemp) cartTemp = localStorage.getItem("cartOwner") === context.getHotelName() ? JSON.parse(cartTemp) : null;
    setCart(cartTemp);

    try {
      const newRoomAddedTmp = JSON.parse(localStorage.getItem('newRoomAdded'));
      setNewRoomAdded(newRoomAddedTmp)
    } catch (err) { }
    setShowNavigationSteps(!!localStorage.getItem('cart'));
  }, []);

  useEffect(() => {
    getTotal();
  }, [context.cookies]);

  useEffect(() => {
    checkEverythingLoaded() && context.showLoader(false);
  }, [
    isPromotionResultReceived,
    isCalendarContentReceived,
    isSearchRoomResultReceived,
    isFilterForSearchRoomsResultReceived,
    timerDeleteCartId
  ]);

  useEffect(() => {
    isFirstRender = true;
    setCart(JSON.parse(localStorage.getItem('cart')));
    if (context.isRedirectFromBookingForm) {
      checkLastItinerary();
    }
  }, [location]);

  useEffect(() => {
    if (isResultAvailableRoomReceived) {
      setIsSearchRoomResultReceived(true);
      syncCartToRooms();
    }
  }, [rooms]);

  const syncCartToRooms = () => {
    let isChanged = false;
    const cart = JSON.parse(localStorage.getItem('cart'));
    if (cart && cart.length > 0) {
      const tarifReservesTmp = [...cart[0].tarifReserves];
      const tarifs = []
      rooms.forEach(room => {
        room.tarifs.forEach(tarif => {
          tarifs.push(tarif)
        })
      })
      tarifReservesTmp.forEach(tarifReserve => {
        const tarif = tarifs.find(tarif => tarif._id === tarifReserve._id)
        if (tarif) {
          if (tarifReserve.categorieAge === 'adulte' && tarifReserve.prix !== tarif.prixAdulte) {
            isChanged = true;
            tarifReserve.prix = tarif.prixAdulte;
          } else if (tarifReserve.categorieAge === 'enfant' && tarifReserve.prix !== tarif.prixEnfant) {
            isChanged = true;
            tarifReserve.prix = tarif.prixEnfant;
          }
        }
      })
      if (isChanged) {
        cart[0].tarifReserves = tarifReservesTmp;
        localStorage.setItem('cart', JSON.stringify(cart));
      }
    }
  }

  const redirectToBookingPage = (totalPrice) => {
    // let cart = localStorage.getItem("cart");
    // try {
    //   cart = JSON.parse(cart);
    //   for (let i = 0; i < cart.length; i++) {
    //     if (cart[i].tarifReserves.length === 0) {
    //       cart.splice(i, 1);
    //       i = i - 1;
    //     }
    //   }
    //   localStorage.setItem("cart", JSON.stringify(cart));
    // } catch (err) {
    //   localStorage.removeItem("cart");
    //   console.error(err);
    // }

    // if (!checkCartExpirationDate() && cart.length > 0) {
    //   navigate(`booking`);
    // }
    // console.log("REDIRECT TO BOOKIKNG")
    setTotalPriceMontant(totalPrice)
    createTimerDeleteCart();
    navigate(`booking`, { state: { totalPrice: totalPrice } });
  };


  const buildRevertData = (cart) => {
    const reserves = []
    cart[0].tarifReserves.forEach(tarifReserve => {
      reserves.push({
        idTypeChambre: tarifReserve.idTypeChambre,
        date: cart[0].datePayload,
        change: tarifReserve.nb,
      })
    })
    return reserves
  }

  const closeModalTimeUpOpened = () => {
    console.log("closeModalTimeUpOpened");
    setIsModalTimeUpOpened(false);
    const cartTmp = JSON.parse(localStorage.getItem("cart"));
    if (cartTmp?.length > 0) {
      const payload = {
        reserves: buildRevertData(cartTmp),
      }
      revertInventory(payload)
        .then(result => {
          // console.log("revertInventory: ", result)
          // localStorage.removeItem('cart');
          // storageChange();
          const lastItinerary = getLastItineraryFromCart();
          // setCart(lastItinerary);
          localStorage.setItem("cart", JSON.stringify(lastItinerary));
          // context.refresh();
        })
    }
  };

  const test = () => {
    console.log("test rooms: ", rooms);
  }

  return (
    <>
      <Header urlHotelName={urlHotelName} />
      <main>
        <LeaderBoard backgroundImage={nosyTanyHely} />

        <section className="contentPage">

          <div class="deco">
            <div class="ico-para ico1 trigger_parallax01">
              <img src={ico1} />
            </div>
            <div class="ico-para ico5 trigger_parallax01">
              <img src={ico3} />
            </div>
            <div class="ico-para ico4 trigger_parallax01">
              <img src={ico4} />
            </div>
          </div>

          {/* <button onClick={test}>test</button> */}
          <NavigationLocation urlHotelName={urlHotelName}></NavigationLocation>
          <div className="container flex flexBetween">
            {/* <div className="leftCol col wow fadeIn" data-wow-delay="800ms" data-wow-duration="3500ms"> */}
            <div className="leftCol col" data-wow-delay="800ms" data-wow-duration="3500ms">
              <RoomSearchForm
                handleChangeRoomSearchForm={handleChangeRoomSearchForm}
                searchRoom={searchRoom}
                rooms={rooms}
                setRooms={setRooms}
                searchRoomPayload={searchRoomPayload}
                handleChangeOccupation={handleChangeOccupation}
                filtersForSearchForRooms={filtersForSearchForRooms}
                daysFirstMonth={daysFirstMonth}
                daysSecondMonth={daysSecondMonth}
                areDaysToBookReloading={areDaysToBookReloading}
                checkIn={checkIn}
                setCheckIn={setCheckIn}
                // NIALA
                // checkOut={checkOut}
                // setCheckOut={setCheckOut}
                checkOut={checkIn}
                setCheckOut={setCheckIn}

                infoCalendar={infoCalendar}
                setInfoCalendar={setInfoCalendar}
                populateCalendar={populateCalendar}
                isCalendarContentLoading={isCalendarContentLoading}
                fillMissingWeekDaysAtMonthStart={fillMissingWeekDaysAtMonthStart}

                // VAOVAO
                nbPersonneActivite={nbPersonneActivite}
                setNbPersonneActivite={setNbPersonneActivite}
                setDatePayload={setDatePayload}
                datePayload={datePayload}
                setOffresDispo={setOffresDispo}
                offresDispo={offresDispo}
                handleChangeNbPersonneTarif={handleChangeNbPersonneTarif}
                nbPersonneTarif={nbPersonneTarif}
                setNbPersonneTarif={setNbPersonneTarif}
                sousTotalPrix={sousTotalPrix}
                setSousTotalPrix={setSousTotalPrix}
                prixTotalReservation={prixTotalReservation}
                setPrixTotalReservation={setPrixTotalReservation}
                offres={offres}
              />
            </div>
            {/* <div className="rightCol col wow fadeIn" data-wow-delay="800ms" data-wow-duration="3500ms"> */}
            <div className="rightCol col" data-wow-delay="800ms" data-wow-duration="3500ms">
              <RoomSearchResult
                // VAOVAO
                redirectToBookingPage={redirectToBookingPage}
                setOffresDispo={setOffresDispo}
                offresDispo={offresDispo}
                datePayload={datePayload}
                // 
                searchRoomPayload={searchRoomPayload}
                rooms={rooms}
                handleChangeLocalStorage={handleChangeLocalStorage}
                isSearchRoomResultReceived={isSearchRoomResultReceived}
                nightsNum={nightsNum}
                searchRoom={searchRoom}
                checkIn={checkIn}
                checkOut={checkOut}
                cartExpirationDate={cartExpirationDate}
                dateMadagascar={dateMadagascar}
                populateCalendar={populateCalendar}
                infoCalendar={infoCalendar}
                printNoResult={printNoResult}
                totalPrice={setTotalPriceMontant}
              />
              {/* <Cart
                infoCalendar={infoCalendar}
                setInfoCalendar={setInfoCalendar}
                totalAllItinerary={totalAllItinerary}
                redirectToBookingPage={redirectToBookingPage}
                removeItemFromCart={removeItemFromCart}
                dateMadagascar={dateMadagascar}
                cart={cart}
                searchRoomPayload={searchRoomPayload}
              /> */}
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <CartTimeUp
        isModalTimeUpOpened={isModalWarningTimeUpOpened}
        setIsModalTimeUpOpened={setIsModalWarningTimeUpOpened}
      />
      {checkEverythingLoaded() && (
        <TimeUp
          isModalTimeUpOpened={isModalTimeUpOpened}
          setIsModalTimeUpOpened={closeModalTimeUpOpened}
          searchRoom={searchRoom}
          searchRoomPayload={searchRoomPayload}
        />
      )}
    </>
  );
};

export default withCookies(App);
