import React, { useState } from 'react';
import img1 from '../../../styles/parc/images/img1.jpg';
import { buildDate, formatDate, formatNumber, getReportTimeLimit } from '../../../services/utils';
import QRCode from 'react-qr-code';
import NavigationLocation from '../../NavigationLocation';
import ClientVoucherPdfComponent from '../../booking/Parc/ClientVoucherPdfComponent';
import ExportComponentToPdf from '../../booking/Parc/ExportComponentToPdf';
import { useNavigate } from 'react-router-dom';

import ico1 from "../../../styles/parc/images/ico1.png";
import ico3 from "../../../styles/parc/images/ico3.png";
import ico4 from "../../../styles/parc/images/ico4.png";
import ReservationReportModal from './ReservationReportModal';

const ClientReservationComponent = ({
    context,
    urlHotelName,
    clientReservations,
    setClientReservations,
}) => {
    const navigate = useNavigate();

    let limitDescription = 50;

    const [tarif, setTarif] = useState(null);

    const [openReport, setOpenReport] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState();

    const openReportModal = (reservation) => {
        setSelectedReservation(reservation);
        setOpenReport(true)
    }
    const closeReportModal = () => {
        setSelectedReservation(undefined);
        setOpenReport(false)
    }

    const generatePdf = (clientReservation) => {
        let reservationDetails = [clientReservation];
        ExportComponentToPdf(
            <ClientVoucherPdfComponent context={context} reservationDetails={reservationDetails} />
            ,
            'voucher.pdf'
        );
    }

    const displayTarifDescription = (tarif) => {
        setTarif(tarif);
    }

    const goToPostoning = (reservationDetail) => {
        navigate(
            `/${urlHotelName}/booking/postpone`,
            {
                state: {
                    reservationDetail
                }
            }
        )
    }

    const test = () => {
        console.log("clientReservations: ", clientReservations);
    }

    // console.log('clientReservations : ', clientReservations);
    return (
        <>
            <NavigationLocation urlHotelName={urlHotelName}>
                <li><span>{context.translate('my_bookings')}</span></li>
            </NavigationLocation>
            <section className="contentPage pageInt recherche-reservation">

                <div class="deco">
                    <div class="ico-para ico1 trigger_parallax01">
                        <img src={ico1} />
                    </div>
                    <div class="ico-para ico5 trigger_parallax01">
                        <img src={ico3} />
                    </div>
                    <div class="ico-para ico4 trigger_parallax01">
                        <img src={ico4} />
                    </div>
                </div>

                <div className="container">
                    {/* <button onClick={test}>test</button> */}
                    <h2 className="wow fadeIn" data-wow-delay="800ms" data-wow-duration="2500ms">{context.translate('my_bookings')}</h2>
                    <div data-wow-delay="800ms" data-wow-duration="2500ms">
                        {
                            clientReservations.map((clientReservation, index) => {
                                const { functionalDateLimit, informativeDateLimit } = getReportTimeLimit(clientReservation.dateReport || clientReservation.dateEntree);
                                const dispoReport = clientReservation.dispoReport !== undefined || clientReservation.dispoReport === 0 ? clientReservation.dispoReport : 20;
                                const reportable = functionalDateLimit.getTime() >= new Date().getTime() && clientReservation.etat === 1 && dispoReport > 0;

                                let description = clientReservation.tarif?.description;
                                if (tarif !== clientReservation.tarif) {
                                    let descriptionTmp = '';
                                    if (description.length > limitDescription) {
                                        for (let index = 0; index < limitDescription; index++) {
                                            let char = description[index];
                                            descriptionTmp += char;
                                        }
                                        description = descriptionTmp + '...';
                                    }
                                }
                                return (
                                    <div key={index} className="boxCenter flex flexBetween col2Conex">
                                        <div className="contentBox" data-wow-delay="800ms" data-wow-duration="2000ms" >
                                            <center>
                                                <div className="titre">
                                                    <h2>{clientReservation.tarif.nomTypeChambre}</h2>
                                                </div>
                                                <div className="img" >
                                                    <img
                                                        src={img1}
                                                        // src={
                                                        //     api.host +
                                                        //     "/" +
                                                        //     clientReservation.tarif.photoCrop[0]?.replace("\\", "/")
                                                        // }
                                                        alt="Parc Nosy Tanihely"
                                                    />
                                                </div>
                                                <h4>{clientReservation.tarif.nomTarif}</h4>
                                                <h5 style={{ color: 'red' }} >{clientReservation.errorMessage}</h5>
                                                <br />
                                                <div className="codeQr">
                                                    <QRCode value={`${clientReservation._id}`}></QRCode>
                                                </div>
                                            </center>
                                            <br />
                                        </div>
                                        <div className="contentBox" data-wow-delay="800ms" data-wow-duration="2000ms">
                                            <div className="txt">
                                                <h2>{context.translate("details")}</h2>
                                                <div style={{
                                                    textAlign: 'justify'
                                                }}>
                                                    {description}
                                                    {
                                                        tarif !== clientReservation.tarif ?
                                                            <h5 >
                                                                <span style={{
                                                                    cursor: 'pointer'
                                                                }} onClick={() => displayTarifDescription(clientReservation.tarif)}>
                                                                    {context.translate('see_more')}
                                                                </span>
                                                            </h5>
                                                            :
                                                            <>
                                                                <h5 >
                                                                    <span style={{
                                                                        cursor: 'pointer'
                                                                    }} onClick={() => displayTarifDescription(null)} >
                                                                        {context.translate('see_less')}
                                                                    </span>
                                                                </h5>
                                                            </>
                                                    }
                                                </div>
                                                <ul>
                                                    <li className='date'><u>Numéro:</u> <strong>{clientReservation.numero}</strong></li>
                                                    <li className="adulte">
                                                        <u>Client:</u>
                                                        <span className="ico"></span><strong> {clientReservation.nom} {clientReservation.prenom}</strong>
                                                    </li>
                                                    <li className={clientReservation.tarif.categorieAge}>
                                                        <span className="ico"></span>
                                                        <u>Age:</u>
                                                        {
                                                            clientReservation.tarif.categorieAge === 'enfant' ?
                                                                <> {context.translate('children')}</>
                                                                :
                                                                <> {context.translate('adults')}</>
                                                        }
                                                    </li>
                                                    <li className="date">
                                                        <span className="ico"></span><u>{context.translate('entry_date')}:</u> <strong>{formatDate(clientReservation.dateEntree)}</strong>
                                                    </li>
                                                    {clientReservation.dateReport &&
                                                        <li className="date">
                                                            <h4><span className="ico"></span>{context.translate('postponement_date')}: <strong>{formatDate(clientReservation.dateReport)}</strong></h4>
                                                        </li>
                                                    }
                                                </ul>

                                                <h5>{context.translate("final_postponement")} {buildDate(informativeDateLimit)}</h5>
                                                <h5>{context.translate("available_postponement")} {dispoReport}</h5>

                                                <br />
                                                <div className="total flex flexBetween">
                                                    <span>{context.translate('price')}</span>
                                                    <div className="prix">{formatNumber(clientReservation.tarif.prix)}<sup>AR</sup></div>
                                                </div>

                                                {reportable ?
                                                    <>
                                                        <span onClick={() => openReportModal(clientReservation)} className="btn-secondary" title="Reporter">{context.translate("postpone")}</span>
                                                        <br />
                                                    </>
                                                    :
                                                    <h4>{context.translate("not_postponable")}</h4>
                                                }

                                                <br />
                                                <span onClick={() =>
                                                    generatePdf(clientReservation)
                                                } className="btn-primary" title="Télécharger en pdf"> télécharger en pdf</span>

                                            </div>
                                        </div>
                                    </div>
                                );
                            }

                            )
                        }
                    </div>
                </div>
            </section>

            {
                (openReport
                    && selectedReservation)
                && (
                    <ReservationReportModal reservations={clientReservations} setReservations={setClientReservations} reservationDetail={selectedReservation} handleClose={closeReportModal} />
                )
            }

        </>
    );
};

export default ClientReservationComponent;