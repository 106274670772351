import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../Wrapper";

const SearchBookingByAccount = ({
  urlHotelName,
  setParamsForSearchBooking,
  searchBookingError, }) => {
  const navigate = useNavigate();
  const context = useContext(ThemeContext);

  const [showPassword, setShowPassword] = useState(false);

  const [customerInformation, setCustomerInformation] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCustomerInformation((item) => ({
      ...item,
      [name]: value,
    }));

    validate({ [name]: value });
    formIsValid({
      ...customerInformation,
      [name]: value,
    });
  };

  const searchBooking = () => {
    validate(customerInformation);
    if (formIsValid(customerInformation)) {
      setParamsForSearchBooking(
        "by-account",
        customerInformation.email,
        customerInformation.password
      );
    }
    // navigate(
    //   `/my-booking/by-account/${customerInformation.email}/${customerInformation.password}`
    // );
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : context.translate('this_field_is_required')
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email invalide.";
    }

    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : context.translate('this_field_is_required')
    }

    setErrors({
      ...temp,
    });
  };

  const formIsValid = (newCustomerInformation) => {
    const isValid =
      newCustomerInformation.email &&
      newCustomerInformation.password &&
      Object.values(errors).every((x) => x === "");
    return isValid;
  };

  return (
    <div className="contentBox wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="2000ms">
      <h3 className="h3">{context.translate('with_your_account')}</h3>

      <form>
        <div className="bloc-chp">
          <div className="blcChp col-100">
            <label>{context.translate('email')} *</label>
            <input
              className={errors.email ? "form-control errorForm" : "form-control"}
              onChange={handleChange}
              name="email"
              type="email"
              placeholder={context.translate("email")}
            />
            {errors.email && <div className="textError">{errors.email}</div>}
          </div>
          <div className="blcChp col-100">
            <label>{context.translate('password')}</label>
            <div className="password">
              <input
                onChange={handleChange}
                name="password"
                className={
                  errors.password ? "form-control errorForm" : "form-control"
                }
                type={showPassword ? "text" : "password"}
                placeholder={context.translate("password")}
              />
              {errors.password && <div className="textError">{errors.password}</div>}
              {/* <span className="material-symbols-rounded pointer toggle-password"></span> */}
              <span
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                className="material-symbols-rounded pointer toggle-password"
              >
                visibility
              </span>
            </div>
          </div>
          {searchBookingError.showSearchBookingError && searchBookingError.isErrorFindByAccount ? (
            <p style={{ color: "red", textAlign: "center" }}>
              {searchBookingError.message}
            </p>
          ) : null}
          <a className="forgotPsw"
              onClick={() => {
                navigate(`/${urlHotelName}/password-reset`);
              }}
          >{context.translate('forgotten_password')}</a>
          <div className="blcChp col-100">
            <input
              onClick={() => {
                searchBooking();
              }}
              type="button"
              name=""
              className="btn-primary"
              value={context.translate('login')}
            />
          </div>

          <div className="blcChp col-100">
            <a
              className="pas-de-compte"
              // onClick={() => {
              //   navigate(`/${urlHotelName}/password-reset`);
              // }}
            >
              {context.translate('no_online_account')}
            </a>
            <div className="inscrit">
              <p>
                <a onClick={() => {
                  navigate(`/${urlHotelName}/register`);
                }}>{context.translate('register_today')}</a>
                {context.translate('to_gain_more_time_during_your_next_reservation')}
              </p>
            </div>
          </div>
        </div>

      </form>

    </div>
  );
};

export default SearchBookingByAccount;
